<template>
  <section>
    <!--  Option that could be default  -->
scoperdoption {{currentOption}}
    <Mesh v-if="currentOption == 'MESH'"
          :options="mergedOptions(currentOption)"
          :package="package"
          :set="set"
          :actionLinksPortalTarget="actionLinksPortalTarget"
          :checkIsSetPackage="checkIsSetPackage"/>

    <Insert v-if="currentOption === 'INS'"
            :options="mergedOptions(type)"
            :package="package"
            :set="set"
            :actionLinksPortalTarget="actionLinksPortalTarget"
            :checkIsSetPackage="checkIsSetPackage"/>

    <Lock v-if="currentOption === 'LOCK'"
          :options="mergedOptions(type)"
          :package="package"
          :set="set"
          :actionLinksPortalTarget="actionLinksPortalTarget"
          :checkIsSetPackage="checkIsSetPackage"/>

    <Cylinder v-if="currentOption === 'CYL'"
              :options="mergedOptions(type)"
              :package="package"
              :set="set"
              :actionLinksPortalTarget="actionLinksPortalTarget"
              :checkIsSetPackage="checkIsSetPackage"/>

    <Wheel v-if="currentOption === 'WHL'"
           :options="mergedOptions(type)"
           :package="package"
           :set="set"
           :actionLinksPortalTarget="actionLinksPortalTarget"
           :checkIsSetPackage="checkIsSetPackage"/>

    <Hinges v-if="currentOption === 'HNG'"
            :options="mergedOptions(type)"
            :package="package"
            :set="set"
            :actionLinksPortalTarget="actionLinksPortalTarget"
            :checkIsSetPackage="checkIsSetPackage"/>

    <LiftTabs v-if="currentOption === 'LFTT'"
               :options="mergedOptions(type)"
               :package="package"
               :set="set"
               :actionLinksPortalTarget="actionLinksPortalTarget"
               :checkIsSetPackage="checkIsSetPackage"/>

    <!--  Available options  -->
    <MashFlaps v-if="currentOption === 'MFLP'"
                :options="options"
                :package="package"
                :set="set"
                :actionLinksPortalTarget="actionLinksPortalTarget"
                :checkIsSetPackage="checkIsSetPackage"/>

    <PushPins v-if="currentOption === 'PSHP'"
               :options="options"
               :package="package"
               :set="set"
               :actionLinksPortalTarget="actionLinksPortalTarget"
               :checkIsSetPackage="checkIsSetPackage"/>

    <FlyscreenPortSets v-if="type === 'PRTS'"
                         :options="options"
                         :package="package"
                         :set="set"
                         :actionLinksPortalTarget="actionLinksPortalTarget"
                         :checkIsSetPackage="checkIsSetPackage"/>

    <HopperHatches v-if="type === 'HOPH'"
                    :options="options"
                    :package="package"
                    :set="set"
                    :actionLinksPortalTarget="actionLinksPortalTarget"
                    :checkIsSetPackage="checkIsSetPackage"/>

    <PetDoor v-if="type === 'PET'"
              :options="options"
              :package="package"
              :set="set"
              :actionLinksPortalTarget="actionLinksPortalTarget"
              :checkIsSetPackage="checkIsSetPackage"/>

    <Accessories v-if="type == 'ACC'"
                 :order="true"
                 :package="package"
                 :set="set"
                 :actionLinksPortalTarget="actionLinksPortalTarget"
                 :checkIsSetPackage="checkIsSetPackage"/>

    <Additions v-if="type === 'ADD'"
               :options="options"
               :package="package"
               :set="set"
               :actionLinksPortalTarget="actionLinksPortalTarget"
               :checkIsSetPackage="checkIsSetPackage"/>

    <Midrail v-if="type === 'MIDR'"
             :options="options"
             :package="package"
             :set="set"
             :actionLinksPortalTarget="actionLinksPortalTarget"
             :checkIsSetPackage="checkIsSetPackage"/>

    <Mullion v-if="type === 'MULL'"
             :options="options"
             :package="package"
             :set="set"
             :actionLinksPortalTarget="actionLinksPortalTarget"
             :checkIsSetPackage="checkIsSetPackage"/>

    <!--  Service components  -->
    <AvailableColour v-if="type === 'AVAILABLECOLOUR'"
                      :package="package"
                      :set="set"
                      :parent="prevOption"
                      :checkIsSetPackage="checkIsSetPackage"/>

    <InsertColour v-if="type === 'INSCOLOUR'"
                   :package="package"
                   :set="set"
                   :checkIsSetPackage="checkIsSetPackage"/>

    <AllOptions v-if="currentStep == 'All Options'"
                 :package="package"
                 :set="set"
                 :editFromSet="editFromSet"
                 :editFromPackage="editFromPackage"
                 :isEdit="isEdit"/>

    <!--  Not implemented  -->
    <OutOfSquare v-if="currentOption == 'OSQR'" :package="package" :actionLinksPortalTarget="actionLinksPortalTarget"/>

    <MidrailsMullions v-if="currentOption == 'MM'" />
  </section>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import merge from '../../../helpers/mergeOptionsSelection'
  import {defineAsyncComponent} from "vue";

  const InsertColour = defineAsyncComponent(() => import('./InsertColour'));
  const LiftTabs = defineAsyncComponent(() => import('./LiftTabs'));
  const OutOfSquare = defineAsyncComponent(() => import('./OutOfSquare'));
  const Accessories = defineAsyncComponent(() => import('./Accessories'));
  const MashFlaps = defineAsyncComponent(() => import('./MashFlaps'));
  const PetDoor = defineAsyncComponent(() => import('./PetDoor'));
  const PushPins = defineAsyncComponent(() => import('./PushPins'));
  const FlyscreenPortSets = defineAsyncComponent(() => import('./FlyscreenPortSets'));
  const HopperHatches = defineAsyncComponent(() => import('./HopperHatches'));
  const AvailableColour = defineAsyncComponent(() => import("./AvailableColour"));
  const Insert = defineAsyncComponent(() => import('./Inserts'));
  const Mesh = defineAsyncComponent(() => import('./Mesh'));
  const Lock = defineAsyncComponent(() => import('./Lock'));
  const Cylinder = defineAsyncComponent(() => import('./Cylinder'));
  const Wheel = defineAsyncComponent(() => import('./Wheel'));
  const Hinges = defineAsyncComponent(() => import('./Hinges'));
  const AllOptions = defineAsyncComponent(() => import('./AllOptions'));
  const Additions = defineAsyncComponent(() => import('./Additions'));
  const Midrail = defineAsyncComponent(() => import('./Midrail'));
  const Mullion = defineAsyncComponent(() => import('./Mullion'));
  const MidrailsMullions = defineAsyncComponent(() => import('./MidrailMullion'));

  export default {
    name: "ScoppedOptions",

    data() {
      return {
        itemOptions: {
          ACC: false,
        },

        prevOption: '',
      }
    },

    props: ['package', 'options', 'type', 'alreadySelected', 'isEdit', 'set', 'editFromSet', 'editFromPackage', 'actionLinksPortalTarget'],

    components: {
      LiftTabs,
      OutOfSquare,
      MashFlaps,
      PetDoor,
      PushPins,
      FlyscreenPortSets,
      HopperHatches,
      AvailableColour,
      Accessories,
      Mesh,
      Insert,
      InsertColour,
      Lock,
      Cylinder,
      Wheel,
      Hinges,
      Additions,
      AllOptions,
      Midrail,
      Mullion,
        MidrailsMullions
    },

    methods: {
      closeModalsACC() {
        this.itemOptions.ACC = false;
      },
      mergedOptions(type) {
        return new merge[type](this.options, this.alreadySelected).combine();
      },
    },

    computed: {
      ...mapState('options', ['allOptions']),
        ...mapGetters({
            currentStep: 'orderPosition/currentStep',
            currentOption: 'orderPosition/currentOption'
        }),

      checkIsSetPackage() {
        if (!this.package && !this.set) {
          return true
        }
        else return false;
      }
    }
  }
</script>

<style scoped>

</style>
