let merge = {};

class BaseMerge {
  constructor(original, alreadySelected) {
    this.original = original;
    this.selected = alreadySelected;
  }
}

merge['MESH'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      _.each(this.original, function (originalItem, key) {
        // Check if selected components equal to
        if (_.isEqual(originalItem.component, that.selected.component)) {
          that.original[key].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['LOCK'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      // Check intersection on default LOCK by name.
      _.each(this.original.default, function (defaultItem, defaultItemKey) {
        if (_.isEqual(defaultItem.name, that.selected.name)) {
          that.original.default[defaultItemKey].selected = true;
        }
      });
      // Check intersection on height LOCK by name.
      _.each(this.original.lock_height, function (heightItem, heightItemKey) {
        if (_.isEqual(heightItem.name, that.selected.option_settings.lock_height)) {
          that.original.lock_height[heightItemKey].selected = true;
        }
      });
      // Check intersection on side LOCK by name.
      _.each(this.original.lock_side, function (sideItem, sideItemKey) {
        if (_.isEqual(sideItem.name, that.selected.option_settings.lock_side)) {
          that.original.lock_side[sideItemKey].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['HNG'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
 //     Check intersection on direction HNG by name.
      _.each(this.original.hinge_direction, function (directionItem, directionItemKey) {
        if (_.isEqual(directionItem.name, that.selected.direction)) {
          that.original.hinge_direction[directionItemKey].selected = true;
        }
      });
//      Check intersection on quantity HNG by name.
      _.each(this.original.hinge_qty, function (qtyItem, qtyItemKey) {
        if (_.isEqual(qtyItem.qty, that.selected.qty)) {
          that.original.hinge_qty[qtyItemKey].selected = true;
        }
      });
      // Check intersection on type HNG by name.
      _.each(this.original.hinge_type, function (typeItem, typeItemKey) {
        if (_.isEqual(typeItem.id, that.selected.id)) {
          that.original.hinge_type[typeItemKey].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['CYL'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      // Check intersection on name of CYL.
      _.each(this.original, function (item, itemKey) {
        if (_.isEqual(item.name, that.selected.name)) {
          that.original[itemKey].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['WHL'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      // Check intersection on direction WHL.
      _.each(this.original, function (item, itemKey) {
        if (_.isEqual(item.name, that.selected.name)) {
          that.original[itemKey].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['INS'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      // Check intersection on name of INS.
      _.each(this.original, function (item, itemKey) {
        if (_.isEqual(item.name, that.selected.name)) {
          that.original[itemKey].selected = true;
        }
      });
    }

    return this.original;
  }
};

merge['LFTT'] = class extends BaseMerge {
  combine() {
    if (this.selected) {
      let that = this;
      // Check intersection on name of CYL.
      _.each(this.original.settings, function (item, itemKey) {
          that.original.settings[itemKey] = that.selected.settings[itemKey];
      });
    }

    return this.original;
  }
};

export default merge;
